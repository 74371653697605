<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- -->

      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>المنتجات</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button variant="primary" :to="{ name: 'admin.products.create' }"> اضافة جديد </b-button>
        </b-col>

        <!-- add-->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." />
            <!-- <v-select
              v-model="statusFilter"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              class="product-filter-select"
              placeholder="Select Status"
            >
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select> -->
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refProductsListTable"
      :items="fetchProducts"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- <template #head(status)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template> -->
      <!-- Column: User -->
      <template #cell(name)="data">
        <b-link :to="{ name: 'admin.products.edit', params: { id: data.item.id } }" class="font-weight-bold">
          <b-media vertical-align="center">
            <template #aside>
              <b-img width="40" :src="data.item.thumbnail_img" :text="avatarText(data.item.name)" />
            </template>
            {{ data.item.name }}
          </b-media>
        </b-link>
      </template>
      <!-- Column: Id -->
      <!-- <template #cell(id)="data">
        <b-link
          :to="{ name: 'apps-product-preview', params: { id: data.item.id } }"
          class="font-weight-bold"
        >
          #{{ data.value }}
        </b-link>
      </template> -->

      <!-- Column: Product Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`product-row-${data.item.id}`"
          size="32"
          :variant="`light-${resolveProductStatusVariantAndIcon(data.item.status).variant}`"
        >
          <feather-icon :icon="resolveProductStatusVariantAndIcon(data.item.status).icon" />
        </b-avatar>
      </template>

      <!-- Column: Client -->
      <!-- <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="data.item.image_url"
              :text="avatarText(data.item.name)"
              :variant="`light-${resolveClientAvatarVariant(data.item.status)}`"
            />
          </template>
        </b-media>
      </template> -->

      <!-- Column: Issued Date -->
      <!-- <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-link :to="{ name: 'admin.products.edit', params: { id: data.item.id } }" class="font-weight-bold">
            <feather-icon :id="`product-row-${data.item.id}-edit-icon`" icon="EditIcon" size="16" class="mx-1 cursor-pointer" />
          </b-link>
          <b-link :to="{ name: 'admin.products.show', params: { id: data.item.id } }" class="font-weight-bold">
            <feather-icon
              :id="`product-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 cursor-pointer"
            />
          </b-link>

          <feather-icon
            :id="`product-row-${data.item.id}-TrashIcon-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="deleteProduct(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProducts"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import useProduct from "./useProduct";
import router from "@/router";
//import productStoreModule from "./productStoreModule";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,

    vSelect
  },
  setup() {
    // const PRODUCT_APP_STORE_MODULE_NAME = "myproducts";

    // // Register module
    // if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
    //   store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule);
    // }

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
    //     store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME);
    // });

    const {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsListTable,

      statusFilter,

      refetchData,

      resolveProductStatusVariantAndIcon,
      resolveClientAvatarVariant
    } = useProduct();

    const editProduct = product => {
      store.commit("myproducts/setProductItem", product);
      router.push({ name: "admin.products.edit", params: { id: product.id } });
    };
    const deleteProduct = product => {
      //  this.productData.items.splice(index, 1);
      store.dispatch("myproducts/deleteProduct", product.id).then(response => {
        refetchData();
      });
    };
    const statusOptions = ["Downloaded", "Draft", "Paid", "Partial Payment", "Past Due"];

    return {
      fetchProducts,
      tableColumns,
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProductsListTable,

      statusFilter,

      refetchData,

      statusOptions,

      avatarText,
      resolveProductStatusVariantAndIcon,
      resolveClientAvatarVariant,
      editProduct,
      deleteProduct
    };
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.product-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "~@resources/scss/vue/libs/vue-select.scss";
</style>
